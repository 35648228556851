import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import moment from "moment"
import classNames from "classnames"
import { toCamelCase } from "../../utils/utils"

import ImageMeta from "../ImageMeta"
import SocialIcon from "./SocialIcon"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"

const socialIconFillColor = "#fff"

export default class ComFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.toggleFooter = this.toggleFooter.bind(this)
  }

  toggleFooter() {}

  render() {
    var comfooterClass = classNames({
      "com-footer": true,
      "color-back": true
    })

    return (
      <StaticQuery
        query={graphql`
          query footerMyQuery {
            allLocationsJson(sort: { fields: locationData___order }) {
              nodes {
                title
                locationData {
                  ...locationDataFields
                }
                pageData {
                  ...locationPageURL
                }
              }
            }
            footerJson {
              footerItems {
                blurbText
                blurbTextEs
                logo
              }
              mainLocation
            }
            site {
              siteMetadata {
                fullPracticeName
              }
            }
          }
        `}
        render={data => {
          let siteMetadata = data.site.siteMetadata

          let locationOne = data.allLocationsJson.nodes[0].locationData
          let locationTwo =
            data.allLocationsJson.nodes[1] &&
            data.allLocationsJson.nodes[1].locationData
          let locationThree =
            data.allLocationsJson.nodes[2] &&
            data.allLocationsJson.nodes[2].locationData
          let locationFour =
            data.allLocationsJson.nodes[3] &&
            data.allLocationsJson.nodes[3].locationData
          let locationFive =
            data.allLocationsJson.nodes[4] &&
            data.allLocationsJson.nodes[4].locationData

          let footerItems = data.footerJson.footerItems

          return (
            <>
              <footer
                id={this.props.pageTitle}
                className={
                  comfooterClass + " is-hidden-mobile " + this.props.pageTitle
                }
              >
                <div className="columns is-centered is-vcentered">
                  <div className="column is-offset-6-tablet is-offset-8-desktop footer-logo-blurb">
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={footerItems.logo}
                      className="logo"
                      width="auto"
                      noLazyload
                      responsive
                    ></ImageMeta>
                    {this.props.language === "es" ? (
                      <h5 className="footer-blurb-text">
                        {footerItems.blurbTextEs}
                      </h5>
                    ) : (
                      <h5 className="footer-blurb-text">
                        {footerItems.blurbText}
                      </h5>
                    )}
                  </div>
                  <div className="column is-6-tablet is-8-desktop"></div>
                </div>
                <div className="columns">
                  <div className="column is-3"></div>

                  <div className="column">
                    <OfficeSection
                      location={locationOne}
                      language={this.props.language}
                    />
                    {/* <div className="footer-hours-social">
                                            <GeneralHours  
                                                className="is-centered" 
                                                footer={true} locationHours={locationOne.locationHours} 
                                                language={this.props.language} 
                                            />
                                        </div> */}
                  </div>

                  <div className="column">
                    <OfficeSection
                      location={locationTwo}
                      language={this.props.language}
                    />
                    {/* <div className="footer-hours-social">
                                            <GeneralHours  
                                                className="is-centered" 
                                                footer={true} locationHours={locationOne.locationHours} 
                                                language={this.props.language} 
                                            />
                                        </div> */}
                  </div>
                  {/* <div className="column">
                                        <OfficeSection location={locationThree} language={this.props.language} />
                                            <div className="footer-hours-social">
                                                <GeneralHours  
                                                    className="is-centered" 
                                                    footer={true} locationHours={locationThree.locationHours} 
                                                    language={this.props.language} 
                                                />
                                            </div>
                                    </div> */}
                  <div className="column">
                    {/* <OfficeSection location={locationFour} language={this.props.language} /> */}
                    <div className="footer-hours-social">
                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={locationOne.locationHours}
                        language={this.props.language}
                      />
                    </div>
                  </div>
                  <div className="column is-narrow footer-contact">
                    {/*  <div className="footer-hours-social">
                                            <GeneralHours  
                                                className="is-centered" 
                                                footer={true} locationHours={locationOne.locationHours} 
                                                language={this.props.language} 
                                            />
                                        </div> */}
                    {/* <div className="office-section">
                      <h7>Contact Us</h7>
                      <a
                        href={`mailto:${locationOne.email}`}
                        title="Send us a message"
                      >
                        {locationOne.email}
                      </a>
                      <p className="small"><a href={"tel:+" + locationOne.phone} title="Call us">{locationOne.phone} </a></p> 
                    </div> */}

                    <div className="columns footer-social">
                      {locationOne.socialAccounts &&
                        locationOne.socialAccounts.map(item => (
                          <React.Fragment key={item.id}>
                            <p className={`small social-icon ${item.platform}`}>
                              <a
                                aria-label="open link in new browser tab"
                                href={item.link}
                                target="_blank"
                                title={item.platform}
                              >
                                <SocialIcon
                                  fillColor={socialIconFillColor}
                                  socialAccount={item.platform}
                                />
                              </a>
                            </p>
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                  <div className="column is-3"></div>
                </div>

                <div className="columns copyright-socials">
                  <div className="column is-offset-3-desktop is-offset-3-tablet is-16 sub-footer-copyright">
                    <p className="footer nuvo-brand">
                      © {moment().format("YYYY")}{" "}
                      {siteMetadata.fullPracticeName}. <br />
                      All rights reserved.
                    </p>
                  </div>
                  <div className="column footer-social">
                    <div className="columns footer-social-icons"></div>
                  </div>
                  <div className="column is-3"></div>
                </div>

                <div className="columns" style={{ alignItems: "flex-end" }}>
                  <div className="column is-10 is-offset-3-desktop is-offset-3-tablet sub-footer-copyright">
                    <p className="footer nuvo-brand-inverse">
                      © {moment().format("YYYY")}{" "}
                      {siteMetadata.fullPracticeName}. <br />
                      All rights reserved.
                    </p>
                    <p
                      className="nuvo-brand footer"
                      dangerouslySetInnerHTML={{
                        __html:
                          this.props.language === "es"
                            ? 'Sitio Web y Producción de Video por <a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Nuvolum</a>'
                            : 'Website, Video & Oral Surgery Marketing by <a href="https://nuvolum.com" style="font-weight: bold;" target="_blank" title="Website, Video & Oral Surgery Marketing by Nuvolum">Nuvolum</a>'
                      }}
                    ></p>
                    {this.props.language === "en" && (
                      <p className="nuvo-brand footer">
                        <a
                          style={{ fontWeight: "bold" }}
                          href="https://getwuwta.com/"
                          title="WUWTA - The Patient Communication Platform That Delivers Superior Outcomes"
                          target="_blank"
                        >
                          Patient experience powered by WUWTA™
                        </a>
                      </p>
                    )}
                  </div>
                  <div className="column">
                    <div className="columns sub-footer-statements">
                      {this.props.language !== "es" &&
                        locationOne.extraLinks &&
                        locationOne.extraLinks[0].statements.map((item, i) => (
                          <div key={i} className="column is-narrow">
                            <p className="footer">
                              <Link to={`${item.pageLink}`} title={item.title}>
                                {item.linkText}
                              </Link>
                            </p>
                          </div>
                        ))}
                      {this.props.language === "es" &&
                        locationOne.extraLinks &&
                        locationOne.extraLinks[0].statements.map((item, i) => (
                          <React.Fragment key={i}>
                            <p className="footer">
                              <Link to={`${item.pageLink}`} title={item.title}>
                                {item.linkText}
                              </Link>
                            </p>
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                  <div className="column is-3"></div>
                </div>
              </footer>

              {/* Mobile footer */}
              <footer
                id={this.props.pageTitle}
                className={
                  comfooterClass + " is-hidden-tablet " + this.props.pageTitle
                }
              >
                <div className="columns is-centered is-vcentered is-mobile">
                  <div className="column is-2"></div>
                  <div className="column is-8">
                    <ImageMeta
                      className="footer-mobile-logo"
                      cloudName="nuvolum"
                      publicId={footerItems.logo}
                      width="auto"
                      noLazyload
                      responsive
                    ></ImageMeta>
                  </div>

                  <div className="column">
                    {this.props.language === "es" ? (
                      <h5 className="footer-blurb-text">
                        {footerItems.blurbTextEs}
                      </h5>
                    ) : (
                      <h5 className="footer-blurb-text">
                        {footerItems.blurbText}
                      </h5>
                    )}
                  </div>
                  <div className="column is-2"></div>
                </div>

                <div className="column">
                  <div className="columns has-text-centered">
                    <FourFooterLocations
                      language={this.props.language}
                      locationOne={locationOne}
                      locationTwo={locationTwo}
                      locationThree={locationThree}
                      locationFour={locationFour}
                      locationFive={locationFive}
                    />
                    <div className="column">
                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={locationOne.locationHours}
                        language={this.props.language}
                      />
                    </div>
                  </div>

                  <div className="columns">
                    <div
                      className="column footer-social"
                      style={{ marginTop: "20px" }}
                    >
                      <div className="columns">
                        {locationOne.socialAccounts &&
                          locationOne.socialAccounts.map((item, i) => (
                            <React.Fragment key={i}>
                              <p
                                className={`small social-icon ${item.platform}`}
                              >
                                <a
                                  aria-label="open link in new browser tab"
                                  href={item.link}
                                  target="_blank"
                                  title={item.platform}
                                >
                                  <SocialIcon
                                    fillColor={socialIconFillColor}
                                    socialAccount={item.platform}
                                  />
                                </a>
                              </p>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-2"></div>

                <div className="columns is-desktop footer-bottom has-text-centered">
                  <div className="column is-4"></div>
                  <div className="column" style={{ marginBottom: "16px" }}>
                    <div className="columns sub-footer-statements">
                      {this.props.language !== "es" &&
                        locationOne.extraLinks &&
                        locationOne.extraLinks[0].statements.map((item, i) => (
                          <div key={i} className="column is-narrow">
                            <p className="footer">
                              <Link to={`${item.pageLink}`} title={item.title}>
                                {item.linkText}
                              </Link>
                            </p>
                          </div>
                        ))}
                      {this.props.language === "es" &&
                        locationOne.extraLinks &&
                        locationOne.extraLinks[0].statements.map((item, i) => (
                          <React.Fragment key={i}>
                            <p className="footer">
                              <Link to={`${item.pageLink}`} title={item.title}>
                                {item.linkText}
                              </Link>
                            </p>
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                  <div className="column is-7 sub-footer-copyright">
                    <p className="footer">
                      © {moment().format("YYYY")}{" "}
                      {siteMetadata.fullPracticeName}. All rights reserved.
                    </p>
                    <p
                      style={{ marginTop: "1rem" }}
                      className="nuvo-brand footer"
                      dangerouslySetInnerHTML={{
                        __html:
                          this.props.language === "es"
                            ? 'Sitio Web y Producción de Video por <a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Nuvolum</a>'
                            : 'Website, Video & Oral Surgery Marketing by <a href="https://nuvolum.com" target="_blank" title="Website, Video & Oral Surgery Marketing by Nuvolum">Nuvolum</a>'
                      }}
                    ></p>
                    {this.props.language === "en" && (
                      <p className="nuvo-brand footer">
                        <a
                          style={{ fontWeight: "bold" }}
                          href="https://getwuwta.com/"
                          title="WUWTA - The Patient Communication Platform That Delivers Superior Outcomes"
                          target="_blank"
                        >
                          Patient experience powered by WUWTA™
                        </a>
                      </p>
                    )}
                  </div>
                  <div className="column is-1"></div>
                  <div className="column is-2"></div>
                </div>
              </footer>
            </>
          )
        }}
      />
    )
  }
}

function OfficeSection(props) {
  return (
    <div className="office-section">
      <h7>
        {props.language === "es"
          ? "Dirección Oficina del Este"
          : props.location.locationName + " Office"}
      </h7>

      <p className="small footer-location-address">
        <a
          aria-label="open link in new browser tab"
          title="Get directions"
          href={props.location.googlePlaceLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.location.streetNumber + " "}
          {props.location.streetName}
          <br />
          {props.location.cityName}
          {", " + props.location.stateAbbr + " "}
          {props.location.cityZip}
        </a>
        <p className="small">
          <a href={"tel:+" + props.location.phone} title="Call us">
            {props.location.phone}{" "}
          </a>
        </p>
        {/* <p className="small"><a href={`mailto:${props.location.email}`} title="Email us">{props.location.email}</a></p>  */}
        <p className="small">
          <a
            href={`/contact-us/?f=${toCamelCase(props.location.locationName)}`}
          >
            Send a Message
          </a>
        </p>{" "}
      </p>
    </div>
  )
}

function FourFooterLocations(props) {
  return (
    <div className="column">
      <h7>
        {props.language === "es"
          ? "Dirección Oficina del Este"
          : props.locationOne.locationName + " Office"}
      </h7>

      <p className="small footer-location-address">
        <a
          aria-label="open link in new browser tab"
          target="_blank"
          href={props.locationOne.googlePlaceLink}
          title={props.locationOne.locationName}
        >
          {props.locationOne.streetNumber + " "}
          {props.locationOne.streetName}
          <br />
          {props.locationOne.cityName}
          {", " + props.locationOne.stateAbbr + " "}
          {props.locationOne.cityZip}
        </a>
        <br />
        <a href={`tel:+1${props.locationOne.phone.replace(/\D/g, "")}`}>
          {props.locationOne.phone}
        </a>
        <br />
        {/* <a href={`mailto:${props.locationOne.email}`}>
          {props.locationOne.email}
        </a>
        <br /> */}
        <p className="small">
          <a
            href={`/contact-us/?f=${toCamelCase(
              props.locationOne.locationName
            )}`}
          >
            Send a Message
          </a>
        </p>
      </p>
      {props.locationTwo && (
        <>
          <br />
          <h7>
            {props.language === "es"
              ? "Dirección Oficina de la Costa"
              : props.locationTwo.locationName + " Office"}
          </h7>

          <p className="small footer-location-address">
            <a
              aria-label="open link in new browser tab"
              target="_blank"
              href={props.locationTwo.googlePlaceLink}
              title={props.locationTwo.locationName}
            >
              {props.locationTwo.streetNumber + " "}
              {props.locationTwo.streetName}
              <br />
              {props.locationTwo.cityName}
              {", " + props.locationTwo.stateAbbr + " "}
              {props.locationTwo.cityZip}
            </a>
            <br />
            <a href={`tel:+1${props.locationTwo.phone.replace(/\D/g, "")}`}>
              {props.locationTwo.phone}
            </a>
            <br />
            {/* <a href={`mailto:${props.locationTwo.email}`}>
              {props.locationTwo.email}
            </a>
            <br /> */}
            <p className="small">
              <a
                href={`/contact-us/?f=${toCamelCase(
                  props.locationTwo.locationName
                )}`}
              >
                Send a Message
              </a>
            </p>
          </p>
        </>
      )}
      {props.locationThree && (
        <>
          <br />
          <h7>
            {props.language === "es"
              ? "Dirección Oficina de la Costa"
              : props.locationThree.locationName + " Office"}
          </h7>

          <p className="small footer-location-address">
            <a
              aria-label="open link in new browser tab"
              target="_blank"
              href={props.locationThree.googlePlaceLink}
              title={props.locationThree.locationName}
            >
              {props.locationThree.streetNumber + " "}
              {props.locationThree.streetName}
              <br />
              {props.locationThree.cityName}
              {", " + props.locationThree.stateAbbr + " "}
              {props.locationThree.cityZip}
            </a>
            <br />
            <a href={`tel:+1${props.locationThree.phone.replace(/\D/g, "")}`}>
              {props.locationThree.phone}
            </a>
            <br />
            <a href={`mailto:${props.locationThree.email}`}>
              {props.locationThree.email}
            </a>
            <br />
            <p className="small">
              <a
                href={`/contact-us/?f=${toCamelCase(
                  props.locationThree.locationName
                )}`}
              >
                Send a Message
              </a>
            </p>
          </p>
        </>
      )}
      {props.locationFour && (
        <>
          <br />
          <h7>
            {props.language === "es"
              ? "Dirección Oficina de la Costa"
              : props.locationFour.locationName + " Office"}
          </h7>

          <p className="small footer-location-address">
            <a
              aria-label="open link in new browser tab"
              target="_blank"
              href={props.locationFour.googlePlaceLink}
              title={props.locationFour.locationName}
            >
              {props.locationFour.streetNumber + " "}
              {props.locationFour.streetName}
              <br />
              {props.locationFour.cityName}
              {", " + props.locationFour.stateAbbr + " "}
              {props.locationFour.cityZip}
            </a>
            <br />
            <a href={`tel:+1${props.locationFour.phone.replace(/\D/g, "")}`}>
              {props.locationFour.phone}
            </a>
            <br />
            <a href={`mailto:${props.locationFour.email}`}>
              {props.locationFour.email}
            </a>
            <br />
            <p className="small">
              <a
                href={`/contact-us/?f=${toCamelCase(
                  props.locationFour.locationName
                )}`}
              >
                Send a Message
              </a>
            </p>
          </p>
        </>
      )}
      {props.locationFive && (
        <>
          <br />
          <h7>
            {props.language === "es"
              ? "Dirección Oficina de la Costa"
              : props.locationFive.locationName + " Office"}
          </h7>

          <p className="small footer-location-address">
            <a
              aria-label="open link in new browser tab"
              target="_blank"
              href={props.locationFive.googlePlaceLink}
              title={props.locationFive.locationName}
            >
              {props.locationFive.streetNumber + " "}
              {props.locationFive.streetName}
              <br />
              {props.locationFive.cityName}
              {", " + props.locationFive.stateAbbr + " "}
              {props.locationFive.cityZip}
            </a>
            <br />
            <a href={`tel:+1${props.locationFive.phone.replace(/\D/g, "")}`}>
              {props.locationFive.phone}
            </a>
            <br />
            <a href={`mailto:${props.locationFive.email}`}>
              {props.locationFive.email}
            </a>
            <br />
            <p className="small">
              <a
                href={`/contact-us/?f=${toCamelCase(
                  props.locationFive.locationName
                )}`}
              >
                Send a Message
              </a>
            </p>
          </p>
        </>
      )}
    </div>
  )
}
